
<template>
  <div class="home">
    <CarrotHeader></CarrotHeader>

    <!-- visual top start -->
    <div class="main-visual-wrap">
      <ThanksDollarViewPopup v-model:showList.sync="pop.isShowThxDollarList" v-if="pop.isShowThxDollarView" @close="pop.hideThanksDollar"></ThanksDollarViewPopup>
      <ThanksDollarListPopup v-if="pop.isShowThxDollarList" @close="pop.hideThxDollarList"></ThanksDollarListPopup>

      <div class="main-visual-wrap-all">
        <div class="main-visual">
          <ul>
            <li>
              <a href="">
                <!-- <img src="@/assets/img/20211008_main.png" alt=""> -->
              </a>
            </li>
          </ul>
          <!--
            <div class="user-box">
              <ul class="user">
                    <li>
                        <ul v-if="store.state.gnb != null">
                            <li @click="mnu.moveDoc('T1')" class="pointer">기안한문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.sign.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.sign.count }}</a></li>
                            <li @click="mnu.moveDoc('T1')" class="pointer">결재할 문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.sign.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.sign.count }}</a></li>
                            <li @click="mnu.moveDoc('T3')" class="pointer" >참조된 문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.referenced.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.referenced.count }}</a></li>
                            <li @click="mnu.moveLink('myPIMS-myVacationList')" class="pointer">잔여 연차<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.vacation.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.vacation.count_txt }}</a></li>
                            <li @click="mnu.moveLink('myPIMS-myEduManagemetList')" class="pointer">교육 학점 <a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.education.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.education.grade_txt }}</a></li>
                            <li @click="mnu.moveDoc('P2')" class="pointer">완료된 문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.done.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.done.count }}</a></li>
                            <li @click="mnu.moveDoc('P3')" class="pointer">반려된 문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.returned.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.returned.count }}</a></li>
                            <li @click="mnu.moveLink('documents-DeptInboxList')" class="pointer">우리 부서수신함 <a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.received.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.received.count }}&</a></li>
                            <li @click="pop.showThanksDollar()" class="pointer">감사 달러<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.thanks.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.thanks.dollar_txt }}</a></li>
                            <li class="pointer" @click="mnu.moveLink('BusinessManagement-ERList')">ER<a @click="gnb.moveLink('BusinessManagement-ERList')" class="bold txt-right float-right pointer">view</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
          -->

          <div class="user-box" >
            <ul class="user_left" >
              <li>
                <ul v-if="store.state.gnb != null">
                  <li @click="mnu.moveDoc('T1')" class="pointer">기안한 문서<a  class="bold txt-right float-right pointer"><span v-if="store.state.gnb.request.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.request.count }}</a></li>
                  <li @click="mnu.moveDoc('T1')" class="pointer">결재할 문서<a  class="bold txt-right float-right pointer"><span v-if="store.state.gnb.sign.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.sign.count }}</a></li>
                  <li @click="mnu.moveDoc('T5')" class="pointer">참조된 문서<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.referenced.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.referenced.count }}</a></li>
                  <li @click="mnu.moveDoc('P2')" class="pointer">완료된 문서<a  class="bold txt-right float-right pointer"><span v-if="store.state.gnb.done.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.done.count }}</a></li>
                  <li @click="mnu.moveDoc('P3')" class="pointer">반려된 문서<a  class="bold txt-right float-right pointer"><span v-if="store.state.gnb.returned.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.returned.count }}</a></li>
                  <li @click="mnu.moveLink('documents-DeptInboxList')" class="pointer">우리 부서수신함 <a  class="bold txt-right float-right pointer"><span v-if="store.state.gnb.received.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.received.count }}</a></li>

                </ul>
              </li>
            </ul>
            <ul class="user_right">
              <li >
                <ul v-if="store.state.gnb != null">
                  <li @click="mnu.moveLink('myPIMS-myVacationList')" class="pointer">잔여 연차<a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.vacation.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.vacation.count_txt }}</a></li>
                  <li @click="mnu.moveLink('myPIMS-myEduManagemetList')" class="pointer">교육 학점 <a class="bold txt-right float-right pointer"><span v-if="store.state.gnb.education.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.education.grade_txt }}</a></li>
                  <li @click="pop.showThanksDollar()" class="pointer">감사 달러<a class="bold txt-right float-right pointer" ><span v-if="store.state.gnb.thanks.is_new=='Y'" class="normal new user-new">N</span>{{ store.state.gnb.thanks.dollar_txt }}</a></li>
                  <li class="pointer" @click="mnu.moveLink('BusinessManagement-ERList')">
                            <span>go ER
                            <img style="width:15px; height:15px" src="@/assets/img/icon-arrow.png" ></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div   style="position:relative; right:-300px; top:50px; float:right; text-align:center;">
          <a href="#quick-menu" >
            <img src="@/assets/img/quick.png">
          </a>
          <br>
          <a href="/documents/SalesManagementPublishList"> <img src="@/assets/img/quick_1.png"></a>
          <br>
          <a href="/ResourceManagement/VehicleReservationLogDay" > <img src="@/assets/img/quick_2.png"></a>
          <br>
          <a href="/ResourceManagement/ClassroomReservationDate" > <img src="@/assets/img/quick_3.png"></a>
          <br>
          <a href="/ResourceManagement/MIScheduleDate" > <img src="@/assets/img/quick_4.png"></a>
        </div>

      </div>

    </div>
    <!-- visual top end -->
    <div class="main-con">
      <div class="con-live-wrap">
        <div class="w-100per">
          <div class="con-notice-wrap">
            <div class="con-notice" style="margin:0px;">
              <div class="title">
                <h3 class="bold">공지사항 (Notice)</h3>
                <router-link to="/CARROTZone/NoticeList" class="more">더보기</router-link>
              </div>
              <hr>
              <table>
                <tbody>
                <tr v-for="(irow, i) in notice.list" :key="i" @click="notice.showDetail(irow.idx)" class="pointer" style="  line-height: 2.86;">
                  <td style="padding-left:13px">· {{ irow.title }}
                    <span v-if="irow.is_new=='Y'" style=" width: 15px; height: 14px;display: inline-block; background-color: #fe0000; border-radius: 50px; text-align: center; line-height: 14px; vertical-align: middle; margin-right: 5px;color: #fff; font-size: 11px; margin-top: -3px; margin-left: 5px;">
                          N
                    </span></td>
                </tr>
                <tr v-if="notice.total==0" class="txt-center" >
                  <td colspan="3">공지사항이 없습니다</td>
                </tr>
                </tbody>
              </table>
              <hr style=" opacity: 0.1; background-color: #000;">
            </div>
            <div class="con-comment" style="margin:0px; margin-top:50px;margin-left:43px; margin-bottom:60px">
              <div stlye=" display: inline-block;float:left">
                <div class="main-line-comment" style="height:240px;">
                  <ul class="comments-banner">
                    <template v-for="(irow, i) in roll2.list" :key="i">
                      <li :class="{ 'prevroll2':roll2.prev==irow.idx, 'currentroll2':roll2.curr==irow.idx, 'nextroll2':roll2.next==irow.idx }">
                        <table>
                          <tr>
                            <td>
                              <img :src="irow.photouri" style="width:56px; height:56px;  border-radius: 200px; position:relative; top:-20px;"/>
                            </td>
                            <td class="comment-box">
                              <table style="width:100%;">
                                <tr>
                                  <td style="text-align: left; width:50%; position: relative; top:-36px; left:-10px;">
                                    <p style="font-size:12px; font-weight: bold; text-align: left; color: #4f4f4f;">{{irow.ename}}({{irow.kname}})
                                      <span v-if="irow.is_today=='Y'" style=" width: 15px; height: 14px;display: inline-block; background-color: #fe0000; border-radius: 50px; text-align: center; line-height: 14px; vertical-align: middle; margin-right: 5px;color: #fff; font-size: 11px; margin-top: -3px; margin-left: 5px;">
                                              N
                                      </span>
                                    </p>
                                  </td>
                                  <td style="text-align: right; width:50%; position: relative; top:-36px; left:-20px;">
                                    <p style="font-size: 12px;  color: #4f4f4f;">{{irow.createdate}}</p>
                                  </td>
                                </tr>
                                <tr style="position: relative; top:-75px; left:-10px; ">
                                  <td colspan="2" class="content-text">
                                    <span style="font-size:15px; text-align: left;color: #4f4f4f;">{{irow.content}}</span>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100per">
          <div class="con-live" style="margin:0px; margin-bottom:70px">
            <div class="title">
              <h3 class="bold">결재처리 현황</h3>
              <!--<router-link to="/documents/AllDocumentList" class="live-num">{{ docs.count }}</router-link>-->
              <router-link to="/documents/AllDocumentList" class="more">더보기</router-link>
            </div>
            <table class="table-col-main">
              <colgroup>
                <col width="124">
                <col width="184">
                <col width="481">
                <col width="152">
                <col width="108">
                <col width="146">
              </colgroup>
              <thead>
              <tr>
                <th>등록일</th>
                <th>양식</th>
                <th>제목</th>
                <th>기안자</th>
                <th>진행상태</th>
                <th>본부/팀</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(irow, i) in docs.list" :key="i" @click="docs.showDetail(irow.idx)" class="pointer">
                <td>{{ irow.cdate }}</td>
                <td>{{ irow.form }}</td>
                <td class="txt-left">{{ irow.title }}</td>
                <td>{{ irow.ename }}({{ irow.kname }})</td>
                <td>
                  <span :class="{ 'col-red':irow.state=='READY' }">{{ irow.state_txt }}</span>
                </td>
                <td>{{ irow.team_name?irow.team_name:(irow.office_name?irow.office_name:'-') }}</td>
              </tr>
              <tr v-if="docs.total==0" class="txt-center">
                <td colspan="6">결재 내역이 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-100per" >
          <div class="con-live" style="margin:0px; ">
            <div class="title">
              <h3 class="bold">전략마케팅 처리 현황</h3>
              <!--<router-link to="/customerManagement/statusSMPList" class="live-num">{{ smp.count }}</router-link>-->
              <router-link to="/customerManagement/statusSMPList" class="more">더보기</router-link>
            </div>
            <table class="table-col-main">
              <colgroup>
                <col width="160">
                <col width="387">
                <col width="162">
                <col width="209">
                <col width="282">
              </colgroup>
              <thead>
              <tr>
                <th>접수일자</th>
                <th>회사명</th>
                <th>본부/팀 배정</th>
                <th>배정일자</th>
                <th>진행상태</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(irow, i) in smp.list" :key="i" @click="smp.showDetail(irow.idx)" class="pointer">
                <td>{{ irow.receipt_date }}</td>
                <td>{{ irow.companyname }}</td>
                <td>{{ irow.team_name?irow.team_name:(irow.office_name?irow.office_name:'-') }}</td>

                <td>{{ irow.process_date }}</td>
                <td>
                  <!-- <a href="/support/marketing/list" class="txt-blue">OPEN</a> -->
                  <span v-if="irow.state=='REQUEST'" class="wait" style="color:red;">대기중</span>
                  <span v-if="irow.state=='PROCESS'" >처리중</span>
                  <span v-if="irow.state=='OPEN'" class="open">OPEN</span>
                  <span v-if="irow.state=='FAIL'" class="fail">Fail</span>
                  <span v-if="irow.state=='STOP'">홀딩</span>
                </td>
              </tr>
              <tr v-if="smp.total==0" class="txt-center">
                <td colspan="5">전략마케팅 내역이 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="con-stat" >
        <div style="padding-top:80px;font-size: 24px;  font-weight: bold;  font-stretch: normal;  font-style: normal;  line-height: 1.12;  letter-spacing: -0.48px;  text-align: center;  color: #4f4f4f;">
          오늘 캐럿의 고객 현황입니다.
        </div>
        <ul class="pt-80">
          <li class="float-left mt-40">
            <img src="@/assets/img/stat_logo.png" style="position:relative; bottom:-28px;">
          </li>
          <li  class=" float-left ">
            <div v-if="store.state.idx_position<=6104" style="margin-left:17px; width: 288px; height: 259.3px; padding: 28.1px 44px 16.3px 36px; box-shadow: 4px -0.2px 15px 0 rgba(3, 3, 3, 0.08); background-color: #fff;  border-top-left-radius: 50px;  border-top-right-radius: 50px;  border-bottom-right-radius: 50px;  border-bottom-left-radius: 10px;">
              <p style="text-align: center;margin-bottom: 17px; font-size: 15px; font-weight: bold; color: #4f4f4f;">고객사 매출 순위</p>
              <p v-for="(irow, i) in sales.list" :key="i" @click="sales.showDetail(irow.idx)" class="pointer" style="text-align: left;margin-bottom: 8px; font-size: 15px; color: #636262;">
                <img v-if="i==0" src="@/assets/img/sales_top1.webp" style="width:22px; height:22px;">
                <img v-if="i==1" src="@/assets/img/sales_top2.webp" style="width:22px; height:22px;">
                <img v-if="i==2" src="@/assets/img/sales_top3.webp" style="width:22px; height:22px;">
                <img v-if="i==3" src="@/assets/img/sales_top4.webp" style="width:22px; height:22px;">
                <img v-if="i==4" src="@/assets/img/sales_top5.webp" style="width:22px; height:22px;">
                {{ irow.company_kname }}
              </p>
              <p style="text-align: right;">
                <router-link to="/customerManagement/statusCarrotTotalSalesList" class="more">더보기</router-link>
              </p>
            </div>
            <div v-if="store.state.idx_position>6104" style="margin-left:17px; width: 288px; height: 259.3px; padding: 28.1px 44px 16.3px 36px; box-shadow: 4px -0.2px 15px 0 rgba(3, 3, 3, 0.08); background-color: #fff;  border-top-left-radius: 50px;  border-top-right-radius: 50px;  border-bottom-right-radius: 50px;  border-bottom-left-radius: 10px;">
              <p style="text-align: center; font-size: 32px; padding-top: 22px; font-weight: bold; color: #4f4f4f; line-height: 42px;" class="carrot-message font-g">Make<br>Your Own<br>CARROT🥕<br>Way </p>
            </div>
          </li>

          <li  class=" float-left " style="position:relative; top:-40px;">
            <img src="@/assets/img/stat_carrotglobal.png" class="img-title">
            <div class="carrotglobal">
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  고객사 수
                </p>

                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.itm.company_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p>
              </div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  Class 수
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.itm.class_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p>
              </div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  학습자 수
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.itm.customer_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p></div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  강사 수
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.itm.tutor_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p>
              </div>
            </div>
            <img src="@/assets/img/stat_carrotsolutions.png" class="img-title">
            <div class="carrotsolutions">
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  고객사 수
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.ctm.company_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p>
              </div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  학습자 수
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">

                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.ctm.customer_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p>
              </div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  강사 수
                </p>
                <p style="font-size: 25px;font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.ctm.tutor_cnt'
                      :duration='3'
                      separator=','
                      :autoinit='true'
                  />
                </p></div>
              <p style="float:left; width: 1px; height: 50px; margin-top: 10px; border: solid 1px #e1e1e1;"></p>
              <div style="float:left; width:24% ">
                <p style="   font-family: MalgunGothic;  font-size: 14px;  line-height: 2.2;  letter-spacing: -0.28px;  text-align: center;  color: #fff;">
                  오늘의 수업 만족도
                </p>
                <p style="font-size: 25px; font-weight: bold;color: #fff;" class="font-g">
                  <vue3-autocounter
                      ref='counter'
                      :startAmount='0'
                      :endAmount='statistics.ctm.learning_satisfaction'
                      :duration='3'
                      separator=','
                      decimalSeparator='.'
                      :decimals='1'
                      :autoinit='true'
                  /><span style="color: #FFD1EA; font-size: 19px;">/5.0</span>
                </p>
              </div>
            </div>
          </li>
          <div class="wrap" style="position:relative; top:-70px;">
            <div class="con">
              <div class="tab">
                <ul>
                  <li :class="{'active':1==main.isTab,'is_lead' : store.state.position=='리드'}" @click="main.setTab(1)" class="pointer">고객사(DB)/상담일지</li>
                  <li :class="{'active':2==main.isTab,'is_lead' : store.state.position=='리드'}" @click="main.setTab(2)" class="pointer">현장경영스케줄</li>
                  <li :class="{'active':3==main.isTab,'is_lead' : store.state.position=='리드'}" @click="main.setTab(3)" class="pointer">명함관리</li>
                  <!-- <li :class="{'active':4==main.isTab,'is_lead' : store.state.position=='리드'}" @click="main.setTab(4)" v-if="store.state.position=='리드'" class="pointer">고객사 매출 순위</li>
              -->
                </ul>
              </div>
              <div class="container" >
                <div id="tab1" class="tab-content" v-if="main.isTab == 1" style="min-height:330px;  ">
                  <table class="mt-20" @mouseover="bbs.isTabMouseOver=1" @mouseleave="bbs.isTabMouseOver=0" >
                    <thead>
                    <tr>
                      <th>작성일</th>
                      <th width="60px">단계</th>
                      <th>HR담당자</th>
                      <th>고객사명</th>
                      <th>상담일지</th>
                      <th>HQ매니저</th>
                    </tr>
                    </thead>
                    <tbody  >
                    <tr v-for="(irow, i) in main.list" :key="i" @click="main.showDetail(irow)" class="pointer">
                      <td width="100" class="txt-gray txt-right">{{ irow.wdate }} </td>
                      <td>{{ irow.step }}</td>
                      <td  width="120">{{ irow.mgr_name }}</td>
                      <td width="120"> <div style="
                                  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
width:120px;height:24px;">{{ irow.kname }}</div>
                      </td>
                      <td>
                        <div @mouseover="bbs.isShow=irow.idx_schedule" @mouseleave="bbs.isShow=0" class=" txt-s1 cal-item txt-left">
                          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 500px; font-size: 14px; line-height: 14px; height:14px; position:relative;">
                            <span v-html="irow.contents"></span>
                          </div>
                          <div v-html="irow.contents" v-if="bbs.isShow==irow.idx_schedule && irow.contents!=''"
                               style="position:absolute; z-index: 555;
                                         min-width: 500px; max-width: 500px; overflow: hidden;   min-height:205px;  max-height:205; background-color: #ffffff; border: 1px solid #ddd; box-shadow: 1px 1px 4px rgb(0,0,0,0.2); padding: 5px 11px; border-radius: 1px;font-size: 14px; font-weight: normal; line-height: 1.64; text-align: left; color: #555555; word-break: break-all; word-break: break-all; display: -webkit-box; word-wrap: break-word;
                                         -webkit-line-clamp: 9; -webkit-box-orient: vertical; text-overflow: ellipsis;">

                          </div>
                        </div>
                      </td>
                      <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
                    </tr>
                    <tr v-if="main.total==0">
                      <td colspan="6">고객사 DB/상담일지가 없습니다.</td>
                    </tr>
                    </tbody>
                  </table>
                  <router-link to="/customerManagement/customerDBList" class="more">더보기</router-link>
                </div>
                <div id="tab2" class="tab-content" v-if="main.isTab == 2" style="min-height:310px;   ">
                  <table class="mt-40 table-col table-cal" style=""  @mouseover="bbs.isTabMouseOver=2" @mouseleave="bbs.isTabMouseOver=0">
                    <colgroup>
                      <col span="7">
                    </colgroup>
                    <thead>
                    <tr>
                      <th>SUN</th>
                      <th>MON</th>
                      <th>TUE</th>
                      <th>WED</th>
                      <th>THU</th>
                      <th>FRI</th>
                      <th>SAT</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th v-for="(irow, i) in bbs.list" :key="i" :class="{ 'today':irow.vdate==bbs.today }">{{ irow.vdate }}</th>
                    </tr>
                    <tr>
                      <td v-for="(irow, i) in bbs.list " :key="i" :class="{ 'today':irow.vdate==bbs.today }">
                        <template v-for="(jrow, j) in irow.times" :key="j">
                          <div v-if="j<6" :class="{ 'txt-s1':jrow.state=='READY', 'txt-s2':jrow.state=='DONE', 'txt-s3':jrow.state=='VISIT' }" @mouseover="bbs.isShow=jrow.idx" @mouseleave="bbs.isShow=0" class="cal-item txt-left">
                            <div @click="bbs.showSchedule(jrow.idx)" style="position: relative; ">
                              <div style ="
                                      white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
width:120px;">{{ jrow.hq_ename }} : {{ jrow.company_kname }}</div>
                              <div v-if="bbs.isShow==jrow.idx" style="position: absolute; min-width: 200px; max-width: 300px; background-color: #f9f9f9; border: 1px solid #ddd; box-shadow: 1px 1px 4px rgb(0,0,0); padding: 5px 11px; border-radius: 1px;font-size: 14px; font-weight: normal; line-height: 1.64; text-align: left; color: #555555; word-break: break-all; z-index: 555;">
                                <!-- v-if="bbs.isShow==jrow.idx" -->
                                매니저 : {{ jrow.hq_ename }}({{ jrow.hq_kname }})<br>
                                방문일시 : {{ jrow.vdate }} {{ jrow.vtime }}<br>
                                고객사 : {{ jrow.company_kname }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <router-link to="/customerManagement/scheduleVisitWeekly" class="more">더보기</router-link>
                </div>

                <div id="tab3" class="tab-content" v-if="main.isTab == 3" style="min-height:330px">
                  <table class="mt-20"  @mouseover="bbs.isTabMouseOver=3" @mouseleave="bbs.isTabMouseOver=0">
                    <thead>
                    <tr>
                      <th>등록일</th>
                      <th>고객사</th>
                      <th>고객사유형</th>
                      <th>단계</th>
                      <th>이름</th>
                      <th>직급</th>
                      <th>HQ매니저</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(irow, i) in main.list" :key="i" @click="main.showDetail(irow)" class="pointer">
                      <td class="w-120px txt-gray txt-center">{{ irow.wdate }}</td>
                      <td class="txt-left">{{ irow.company_kname }}</td>
                      <td>{{ irow.company_type }}</td>
                      <td>{{ irow.step }}</td>
                      <td>{{ irow.kname }}</td>
                      <td>{{ irow.position }}</td>
                      <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
                    </tr>
                    <tr v-if="main.total==0">
                      <td colspan="7">명함 정보가 없습니다.</td>
                    </tr>
                    </tbody>
                  </table>
                  <router-link to="/customerManagement/businessCardList" class="more">더보기</router-link>
                </div>
                <div id="tab4" class="tab-content" v-if="main.isTab == 4">
                  <table class="mt-20">
                    <tbody>
                    <tr v-for="(irow, i) in main.list" :key="i" @click="main.showDetail(irow)" class="pointer">
                      <td class="bold" width="80">{{ irow.rank }}</td>
                      <td class="txt-left">{{ irow.company_kname }}</td>
                      <td class="w-120px txt-right">{{ main.number_format(irow.tot_sales) }} 원</td>
                    </tr>
                    <tr v-if="main.total==0">
                      <td colspan="3">매출 정보가 없습니다.</td>
                    </tr>
                    </tbody>
                  </table>
                  <router-link to="/customerManagement/statusCarrotTotalSalesList" class="more">더보기</router-link>
                </div>

              </div>
            </div>

          </div>
        </ul>
      </div>
      <div class="con-holiday">
        <h2 class="bold">CARROTians 휴가/교육 현황 By Week</h2>
        <p @click="main.moveLink('myPIMS-AllVacationList')" class="txt-right w-100per mt-10 mb-10 font-16 pointer">더보기 </p>
        <div class="calendar-wrap" style="position:relative; top:-50px;">
          <div v-for="(irow, i) in main.dates" :key="i" class="calendar">
            <p>{{ irow.date }} <span class="day">{{ irow.day }}</span></p>
            <ul>
              <template v-for="(jrow, j) in main.elist" :key="j">
                <li v-if="irow.cdate==jrow.cdate" class="special-book">
                  {{ jrow.text }}
                </li>
              </template>
              <template v-for="(jrow, j) in main.vlist" :key="j">
                <li v-if="irow.cdate==jrow.cdate">
                  {{ jrow.text }}<span class="txt-orange"> - {{ jrow.type }}</span>
                </li>
              </template>
            </ul>
            <!--
            <div v-if="irow.count>8" @mouseover="irow.isMore=!irow.isMore" class="more">
              <img src="@/assets/img/arrow-btn.png">
            </div>
            -->
          </div>
        </div>
      </div>
      <div class="con-ceo">
        <ul class="pt-80">
          <li class="float-left mt-20">
            <img src="@/assets/img/ceo-img.png" alt="ceo">
          </li>
          <li v-if="main.info" class="ceo-message float-left w-510px">
            <span>CEO HOT Line</span>
            <p class="title">{{ main.info.ceomessage.title }}</p>
            <div class="sub" v-html="main.info.ceomessage.summary"></div>
          </li>
          <p class="more float-right  underline" style="text-align:right">
            <router-link :to="{ name:'CARROTZone-CEOMessageView-idx', params:{ idx:main.info.ceomessage.idx } }" target="_blank" >자세히 보기</router-link>
          </p>
        </ul>
      </div>
      <div class="con-media-board">
        <div class="con-media-board-wrap" >
          <div class="con-media-board-div">
            <div class="con-media-board-txt">
              <h2 class="bold">#Meet_and_Greet</h2>
              <p>
                지난 7일간 가장 인기있었던 글을<br>
                만나보세요!
              </p>
            </div>
            <div class="con-media-board-img">
              <carousel :items-to-show="2.5"
                        :autoplay="3000" :wrap-around="true"
                        :autoplay-hover-pause="false"
              >
                <slide v-for="(irow, slide) in mediazoneTopList.list" :key="slide" >
                  <div  v-if="mediazoneTopList.total!=slide">
                    <img style="width:440.3px; height: 330.3px; border-top-right-radius: 50px;border-top-left-radius: 50px;" :src="irow.thumb">
                    <div style=" width:440.3px;height:180px;  background-color: #fff;  border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;" >
                      <p style=" padding-top:20px; height: 63px;font-size: 22px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 1.55; letter-spacing: -0.44px; text-align: center; color: #282828;">
                        {{irow.title}}
                      </p>
                      <p style="padding-top:40px;font-size: 16px;  font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.56; letter-spacing: -0.32px; text-align: center;color: #282828;">
                        <img style=" width:44px; height: 44px; border-radius: 200px;" :src="irow.thumb">
                        {{irow.ename}}({{irow.kname}})
                      </p>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>

          </div>
        </div>

        <div class="media-new-wrap" >

          <p class="txt-right w-100per mb-10 font-16 pointer" style="position:relative;right:40px;"><router-link :to="{ name:'CARROTZone-MediaZoneList' }" class="dp-block">더보기 </router-link></p>
          <div v-if="main.isLoaded==true" class="board-media">
            <div v-for="(irow, i) in main.mediazone.list" :key="i" class="media-con " style=" border: 1px solid #e6e6e6;">
              <ul>
                <li class="con-title mt-15" style="text-align:left">
                  <img style="margin-left:10px;margin-right:12px; width:44px; height: 44px; border-radius: 200px;" :src="irow.photouri">
                  <span>{{ irow.ename }}({{ irow.kname }})</span>
                  <img v-if="irow.is_today=='Y' && irow.is_new=='Y' " style="float:right" src="@/assets/img/media-new.png">
                </li>

                <router-link :to="{ name:'CARROTZone-MediaZoneView-idx', params:{ idx:irow.idx } }">
                  <li class="con-img pointer">
                    <img :src="irow.thumb" :alt="irow.title">
                  </li>
                </router-link>
                <div class="mt-20 comment-list">
                  <div class="title-box type1">
                    <span class="tit" @click="bbs_comment.setGood(irow.idx)"></span>
                    <span class="pointer" @click="bbs_comment.setGood(irow.idx)" style="color:#fd6011; font-size: 15px; line-height: 2.07;">
                                      <img v-if="irow.is_good=='Y'" src="@/assets/img/media-like-on.png">
                                      <img v-if="irow.is_good=='N'" src="@/assets/img/media-like.png">
                                      {{ irow.good}}
                                    </span>
                    <span style="color:#fd6011;  font-size: 15px; line-height: 2.07; padding-left:13px;">
                                      <img src="@/assets/img/media-comment.png" style="position:relative; bottom:1px;">
                                      {{ irow.cnt_cmt }}
                                      </span>
                  </div>
                </div>
                <li class="con-title">
                  <p class="title bold">
                    <span class="con-title" style="  font-size: 16px;">{{ irow.title }}</span>
                    <!--새로운게시물이 올라왔을 때-->
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="quick-menu" class="con-quick-menu-wrap">
          <h2 class="bold">QUICK MENU</h2>
          <div class="icon-wrap">
            <div class="icon-div">
              <p class="bold">부탁해요 업무협조 게시판</p>
              <ul>
                <li @click="main.moveExternalLink('https://carrotsds.atlassian.net/servicedesk/customer/portals')">
                  <img src="@/assets/img/icon-01_new.svg" alt="이러닝 업무협조">
                  <span>이러닝<br>업무협조</span>
                </li>
                <li @click="main.moveLink('BusinessManagement-ManagementPlanList')">
                  <img src="@/assets/img/icon-02_new.svg" alt="">
                  <span>경영기획<br>업무협조</span>
                </li>
                <li @click="main.moveExternalLink('https://carrotsds.atlassian.net/servicedesk/customer/portals')">
                  <img src="@/assets/img/icon-03_new.svg" alt="">
                  <span>SPAC<br>업무협조</span>
                </li>
                <li @click="main.moveExternalLink('https://carrotsds.atlassian.net/servicedesk/customer/portals')">
                  <img src="@/assets/img/icon-04_new.svg" alt="">
                  <span>연구소<br>업무협조</span>
                </li>
              </ul>
            </div>
            <div class="icon-div">
              <p class="bold">함께 쓰는 캐럿자산</p>
              <ul>
                <li @click="main.moveLink('documents-SalesManagementPublishList')">
                  <img src="@/assets/img/icon-05_new.svg" alt="" style="width: 55px;">
                  <span>세금계산서<br>발행 등록 관리</span>
                </li>
                <li @click="main.moveLink('ResourceManagement-VehicleReservationLogDay')">
                  <img src="@/assets/img/icon-06_new.svg" alt="">
                  <span>차량운행 <br>예약일지</span>
                </li>
                <li @click="main.moveLink('ResourceManagement-ClassroomReservationDate')">
                  <img src="@/assets/img/icon-07_new.svg" alt="">
                  <span>글로벌센터<br>예약 현황</span>
                </li>
                <li @click="main.moveLink('ResourceManagement-MIScheduleDate')">
                  <img src="@/assets/img/icon-08_new.svg" alt="">
                  <span>전임교수<br>스케줄</span>
                </li>
              </ul>
            </div>
            <div class="icon-div">
              <p class="bold">도와줘요 Manual</p>
              <ul>
                <li @click="main.moveLink('CARROTZone-CarrotManualList')">
                  <img src="@/assets/img/icon-09_new.svg" alt="">
                  <span>CARROT<br>Manual</span>
                </li>
                <li>
                  <a href="http://book.carrotenglish.com/sample.html" target="_blank">
                    <img src="@/assets/img/icon-10_new.svg" alt="">
                    <span>고도화 컨텐츠<br>스마트러닝 샘플보기</span>
                  </a>
                </li>
                <li>
                  <a href="http://old.carrotians.net/intranet_snew/error_index.html" target="_blank">
                    <img src="@/assets/img/icon-11_new.svg" alt="">
                    <span>CARROT 교재<br>오류 신고함</span>
                  </a>
                </li>
                <li>
                  <a href="http://old.carrotians.net/intranet_snew/email_template.html" target="_blank">
                    <img src="@/assets/img/icon-12_new.svg" alt="">
                    <span>CARROT 업무<br>(영문)</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="con-edu">
            <ul>
              <li>
                <p class="main-title">성장해 나가는 <br><span class="txt-orange">SMART</span>한 <br>캐러시안!</p>
              </li>
              <li class="pointer" onclick="window.open('https://carrot.hunet.co.kr/Primelogin/LoginGate')">
                <p class="img"><img src="@/assets/img/carrot_edu_1.png" alt="edu"></p>
                <p class="title bold font-15">On-line ACADEMY ></p>
                <p class="sub">
                  캐러시안의 성장을<br>견인하는 온라인 수업!
                </p>
              </li>
              <li class="pointer" onclick="window.open('http://old.carrotians.net/library/')">
                <p class="img"><img src="@/assets/img/carrot_edu_2.png" alt="edu"></p>
                <p class="title bold font-15">LIBRARY ></p>
                <p class="sub">
                  캐럿의 모든 컨텐츠가<br>모여있는 곳!
                </p>
              </li>
              <li class="pointer" onclick="window.open('https://carrotians.imooc.co.kr/bmain')">
                <p class="img"><img src="@/assets/img/carrot_edu_3.png" alt="edu"></p>
                <p class="title bold font-15">i:MOOC ></p>
                <p class="sub">
                  가치의 확장,<br>지식러닝의 혁신!
                </p>
              </li>
              <li class="pointer" onclick="window.open('http://old.carrotians.net/intranet_snew/pop_apply_leader.html', 'CARROT Leadership 지원 Button 제도','width=600,height=750,location=no,status=no,scrollbars=yes')">
                <p class="img"><img src="@/assets/img/carrot_edu_4.png" alt="edu"></p>
                <p class="title bold font-15"><span class="txt-orange">캐럿 리더십</span> 지원 ></p>
                <p class="sub">
                  CARROT의 Lead로 성장하고 싶다면?
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer">
          <h3>
            서로에게서 최고의 것을 발견하고, <br>
            최고의 것을 주는 사람들, 우리는 CARROT입니다.
          </h3>
          <ul>
            <li>
              <a href="https://www.instagram.com/carrotenglish/" target="_blank">
                <img src="@/assets/img/ico_social1.png"><br>
                당근영어
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/carrotglobal/" target="_blank">
                <img src="@/assets/img/ico_social1.png"><br>
                캐럿글로벌
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Carrot-Farm/100014542383160" target="_blank">
                <img src="@/assets/img/ico_social2.png"><br>
                페이스북
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/carrotblog" target="_blank">
                <img src="@/assets/img/ico_social3.png"><br>
                블로그
              </a>
            </li>
          </ul>
          <div class="txt-right">
            <select class="gnb-fast-select" onchange="window.open(this.value)">
              <option value="https://carrotglobal.com/" >CARROT HUB</option>
              <option value="https://www.carrotenglish.kr/">전화영어:모바일 화상</option>
              <option value="https://carrotjr.com/">화상영어:주니어</option>
              <option value="https://carrotglobal.com/global/">기업교육:글로벌 역량</option>
              <option value="http://www.carrotglobal.net/">CARROT Global</option>
              <option value="https://www.carrotglobal.com/carrot/index.html">회사소개</option>
              <option value="http://www.how-writing.com/how_intro">HOW</option>
              <option value="http://www.thespac.net/new/">SPAC</option>
              <option value="http://www.thegcat.com/en/">gCAT</option>
              <option value="https://www.imooc.co.kr/">i:MOOC</option>
              <option value="http://aitutoring.co.kr/">AI튜터</option>
              <option value="http://olivepop.net/">올리브팝</option>
              <option value="http://navigatingcareer.com/">KUDER</option>
              <option value="https://goepta.com/">goEPTA</option>
              <option value="http://bhappy.co.kr/">밝음심리센터</option>
              <option value="http://ehsedu.kr/">EHS</option>
              <option value="https://carrotedu.com/">CARROT EDU</option>
            </select>

            <!-- <select v-model="gnb.selected_admin" @change="gnb.moveSite_admin" class="gnb-fast-select"> -->
            <select class="gnb-fast-select">
              <option value="">Admin</option>
              <option value="admin_ctm">CTM</option>
              <option value="admin_itm">ITM</option>
              <option value="admin_spac">SPAC</option>
              <option value="admin_lms_old">LMS(Old)</option>
              <option value="admin_lms_new">LMS(NEW)</option>
              <option value="admin_how">HOW</option>
              <option value="admin_b2b">B2B 통합 사이트</option>
              <option value="admin_crm">밝음 CRM</option>
              <option value="admin_bhappy">밝음 홈페이지 관리자</option>
              <option value="admin_mr8">팔선생 게시판 관리자</option>
              <option value="admin_daily">DAILY WRITING(B2B)</option>
              <option value="admin_lab">Speaking Lab 관리자</option>
              <option value="admin_imooc">i:MOOC 관리자</option>
              <option value="admin_imooc_vn">i:MOOC 베트남 관리자</option>
              <option value="admin_kuder">Kuder 관리자</option>
              <option value="admin_global">캐럿글로벌 게시판 관리자</option>
              <option value="admin_platform">통합플랫폼 관리자</option>
              <option value="admin_olive">올리브팝 관리자</option>
            </select>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import { useStore } from 'vuex'
import ThanksDollarViewPopup from '@/components/popup/personality/ThanksDollarViewPopup.vue';
import ThanksDollarListPopup from '@/components/popup/personality/ThanksDollarListPopup.vue';
import Swal from "sweetalert2";
import Vue3autocounter from "vue3-autocounter";
//import $ from 'jquery';

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
  name: 'Main',
  components: {
    ThanksDollarViewPopup,
    ThanksDollarListPopup,
    'vue3-autocounter': Vue3autocounter,
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();


    const pop = reactive({
      // Popup 노출 여부
      isShowThxDollarView : false,
      isShowThxDollarList : false,

      showThanksDollar : () => {
        pop.isShowThxDollarView = false;
        pop.isShowThxDollarList = false;
        if( store.state.gnb.thanks.is_new == 'Y' ){
          pop.isShowThxDollarView = true;
        } else {
          pop.isShowThxDollarList = true;
        }
      },
      hideThanksDollar : () => {
        pop.isShowThxDollarView = false;
      },
      hideThxDollarList : () => {
        pop.isShowThxDollarList = false;
      }
    });

    const mnu = reactive({
      moveDoc : (tp) => {
        router.push({
          name: 'documents-AllDocumentList',
          query: {
            doc_type: tp
          }
        });
      },
      moveLink: (name) => {
        router.push({
          name : name
        });
      },
    });

    const docs = reactive({
      list  : {},
      total : 0,
      count : 0,

      showDetail : (id) => {
        router.push({
          name : 'documents-Document-id',
          params : {
            id : id
          }
        });
      },

      doSearch : () => {
        let params = { };
        axios.get("/rest/main/getDocumentList", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            docs.list  = res.data.list;
            docs.total = res.data.total;
            docs.count = res.data.count;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      }
    });
    const notice = reactive({
      list  : {},
      total : 0,
      count : 0,

      showDetail : (id) => {
        router.push({
          name: 'CARROTZone-NoticeView-idx',
          params : {
            idx : id
          }
        });
      },

      doSearch : () => {
        let params = {
          page : 1,
          rows : 5,
        };
        axios.get("/rest/carrotzone/noticeList", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            notice.list  = res.data.list;
            notice.total = res.data.total;
            notice.count = res.data.count;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });

      }
    });
    const statistics = reactive({
      itm  : {
        company_cnt:0,
        customer_cnt:0,
        tutor_cnt:0,
        class_cnt:0
      },
      ctm : {
        company_cnt:0,
        customer_cnt:0,
        tutor_cnt:0,
        learning_satisfaction:0
      },
      total : 0,
      count : 0,
      doSearch : () => {
        let params = {
          page : 1,
          rows : 3,
        };
        axios.get("/rest/external/getStatistics", { params : params }).then((res) => {
          if( res.data.err == 0 ){ 
            if(res.data.data.itm!=false)
              statistics.itm  = res.data.data.itm;
            if(res.data.data.ctm!=false)
              statistics.ctm  = res.data.data.ctm;

            console.log(statistics.ctm);
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });

      }
    });
    const sales = reactive({
      list  : {},
      total : 0,
      count : 0,

      showDetail : (id) => {
        router.push({
          name: 'CARROTZone-NoticeView-idx',
          params : {
            idx : id
          }
        });
      },

      doSearch : () => {
        let params = {
          sdate : '', edate : '', company : '', syear : (new Date()).getFullYear(), limit :5
        };
        //
        axios.get("/rest/customermgr/getTotalSalesList", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            sales.list  = res.data.list;
            sales.total = res.data.total;
            sales.count = res.data.count;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });

      }
    });
    const mediazoneTopList = reactive({
      list  : {},
      total : 0,
      count : 0,
      top1  : {
        thumb:"",
        kname:"",
        title:"",
      },
      top2  : {
        thumb:"",
        kname:"",
        title:"",
      },
      top3  : {
        thumb:"",
        kname:"",
        title:"",
      },

      showDetail : (id) => {
        router.push({
          name : 'documents-Document-id',
          params : {
            id : id
          }
        });
      },

      doSearch : () => {
        let params = { };
        axios.get("/rest/carrotzone/mediazoneListTopList", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            mediazoneTopList.list = res.data.list;
            mediazoneTopList.total = res.data.total;
            //  mediazoneTopList.list[mediazoneTopList.total]="";
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      }
    });
    const smp = reactive({
      list  : {},
      total : 0,
      count : 0,

      showDetail : (idx) => {
        router.push({
          name : 'customerManagement-statusSMPView-idx',
          params : {
            idx : idx
          }
        });
      },

      doSearch : () => {
        let params = { };
        axios.get("/rest/main/getSMPList", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            smp.list  = res.data.list;
            smp.total = res.data.total;
            smp.count = res.data.count;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      }
    });
    const main = reactive({
      isLoaded : false,
      isTab : 1,

      info : {
        cnt_schedule : 0, cnt_bizcard : 0,
        ceomessage : {}
      },
      list : [], total : 0,
      dates : [], vlist : [], elist : [],
      mediazone : { err : 200 },

      number_format : (num) => {
        return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      setTab : (tab) => {
        main.isTab = tab;
        let u = '';
        let p = {};
        let d = new Date();
        let td = [d.getFullYear(), d.getMonth()>8?d.getMonth()+1:'0'+(d.getMonth()+1), d.getDate()>9?d.getDate():'0'+d.getDate()].join('-');
        switch(tab){
          case 1:
            u = 'getCustomerDBDetailList';
            p = {
              page : 1, rows : 5
            };
            break;

          case 2:
            u = 'getScheduleDates';
            p = {
              sdate : td, edate : td
            };
            break;

          case 3:
            u = 'getBizcardList';
            p = {
              page : 1, rows : 5, state : "Y"
            };
            break;

          case 4:
            u = 'getTotalSalesList';
            p = {
              sdate : '', edate : '', company : '', syear : (new Date()).getFullYear(), limit :15
            };
            break;
        }

        main.doSearch(u, p);
      },


      doSearch : (u, p) => {
        main.list  = [];
        main.total = 0;
        axios.get("/rest/customermgr/" + u, { params : p }).then((res) => {
          if( res.data.err == 0 ){
            if( main.isTab == 2 ){
              let l = res.data.list[0].times;
              let cnt = (l.length>3?3:l.length);
              for(let i=0; i<cnt; i++){
                main.list.push(l[i]);
              }
              main.total = main.list.length;
            } else {
              main.list  = res.data.list;
              main.total = res.data.total;
            }
          } else {
            console.log(res.data);
          }
        });
      },

      initSearch : () => {
        axios.get("/rest/main/getDefaultInfo", { params : {} }).then((res) => {
          if( res.data.err == 0 ){
            main.info = res.data;

            main.makeCalendarDates();
            main.mediazone = main.info.mediazone;
            main.isLoaded = true;
          } else {
            console.log(res.data);
          }
        });
      },

      makeCalendarDates : () => {
        let days  = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
        let sdate = (new Date(main.info.sdate)).getTime();
        let edate = (new Date(main.info.edate)).getTime();
        for(let i=sdate; i<=edate; i+=86400000){
          let td   = (new Date(i));
          let day  = days[td.getDay()];
          let date  = td.getDate();
          let cdate = [ td.getFullYear(), td.getMonth()<9?'0'+(td.getMonth()+1):(td.getMonth()+1), td.getDate()<10?'0'+td.getDate():td.getDate() ].join('-');
          main.dates.push({
            day    : day,
            date   : date,
            cdate  : cdate,
            count  : 0,
            isMore : false
          });
        }

        let list = main.info.education.list;
        for(let i=0; i<list.length; i++){
          let irow = list[i];

          for(let j=0; j<main.dates.length; j++){
            let jrow = main.dates[j];

            if( irow.sdate <= jrow.cdate && jrow.cdate <= irow.edate ){
              let nitm = { idx: irow.idx };
              nitm.cdate = jrow.cdate;
              nitm.text = irow.title;

              main.elist.push(nitm);
              jrow.count++;
            }
          }
        }

        list = main.info.vacation.list;
        for(let i=0; i<list.length; i++){
          let irow = list[i];

          if( irow.days > 1 ){
            for(let j=0; j<irow.days; j++){
              let cd = new Date(irow.sdate);
              cd.setDate(cd.getDate() + j);

              let nitm = { idx: irow.idx };
              nitm.cdate = [cd.getFullYear(),((cd.getMonth() + 1)>9 ? '' : '0') + (cd.getMonth() + 1),(cd.getDate()>9 ? '' : '0') + cd.getDate()].join('-');
              nitm.text = irow.ename + '(' + irow.kname + ')';
              nitm.type = irow.utype_txt;

              for(let k=0; k<main.dates.length; k++){
                if( nitm.cdate == main.dates[k].cdate ){
                  main.dates[k].count++;
                  break;
                }
              }

              main.vlist.push(nitm);
            }
          } else {
            let nitm = { idx: irow.idx };
            nitm.cdate = irow.sdate;
            nitm.text = irow.ename + '(' + irow.kname + ')';
            nitm.type = irow.utype_txt.replace('반차', '');

            for(let k=0; k<main.dates.length; k++){
              if( nitm.cdate == main.dates[k].cdate ){
                main.dates[k].count++;
                break;
              }
            }

            main.vlist.push(nitm);
          }
        }
      },

      showDetail : (o) => {
        let loc = 'customerDBViewTab1';
        switch(main.isTab){
          case 1: loc = 'customerManagement-customerDBViewTab1-idx'; break;
          case 2: loc = 'customerManagement-scheduleVisitView-idx';  break;
          case 3: loc = 'customerManagement-businessCardView-idx';   break;
          case 4:
            sessionStorage.setItem('statusCarrotTotalSalesList', JSON.stringify({
              company : o.idx_company
            }));
            loc = 'customerManagement-statusCarrotTotalSalesList';
            break;
        }

        router.push({
          name : loc,
          params : {
            idx : o.idx
          }
        });
      },

      moveLink: (name) => {
        router.push({
          name : name
        });
      },
      moveExternalLink:(link)=>{
        window.open(link);
      },

    });

    const roll2 = reactive({
      isLoaded : false,
      list : [],

      rollId: null,
      interval: 4000,
      prev : 0,
      curr : 0,
      next : 0,

      is_reverse : false,

      up : () => {
        roll2.is_reverse = true;

        if( roll2.rollId ){
          clearInterval(roll2.rollId);
        }

        let tmp = roll2.next;
        roll2.next = roll2.curr;
        roll2.curr = roll2.prev;
        roll2.prev = tmp;

        roll2.rollId = setInterval(() => {
          let tmp = roll2.prev;
          roll2.prev = roll2.curr;
          roll2.curr = roll2.next;
          roll2.next = tmp;

        }, roll2.interval);
      },
      moveLink : (idx) => {
        router.push({
          name: 'CARROTZone-NoticeView-idx',
          params: {
            idx: idx
          }
        });
      },

      doSearch : () => {
        let params = {
          cnt : 30
        };
        axios.get("/rest/carrotzone/mediaComments", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            roll2.list = res.data.list2;
            roll2.rollInit();
          } else {
            console.log(res.data);
          }
        });
      },

      //롤링 배너 초기화
      rollInit: (newinterval) => {
        if(parseInt(newinterval) > 0){
          roll2.interval = newinterval;
        }
        if( roll2.rollId ){
          clearInterval(roll2.rollId);
        }



        if( roll2.list.length == 1 ){
          roll2.prev = roll2.list[0]["idx"];
          roll2.curr = roll2.list[0]["idx"];
          roll2.next = roll2.list[0]["idx"];
        } else if ( roll2.list.length == 2 ){
          roll2.prev = roll2.list[0]["idx"];
          roll2.curr = roll2.list[0]["idx"];
          roll2.next = roll2.list[1]["idx"];
        } else if ( roll2.list.length >= 3 ) {
          roll2.prev = 2;
          roll2.curr = 0;
          roll2.next = 1;
        } else {
          roll2.prev = 0;
          roll2.curr = 0;
          roll2.next = 0;
        }

        roll2.isLoaded = true;

        roll2.rollId = setInterval(() => {


          if( roll2.next==roll2.list.length){
            roll2.prev = roll2.curr;
            roll2.curr = roll2.next;
            roll2.next = 0;
          }
          roll2.prev = roll2.curr;
          roll2.curr = roll2.next;
          roll2.next = roll2.next+1;

          // if(roll2.list.length-1 =)

          /*
          if (roll2.list.length >= 3 ){
            if(roll2.next == roll2.list[roll2.list.length-1]["idx"]) {
              roll2.prev = roll2.list[1]["idx"];
              roll2.curr = roll2.list[2]["idx"];
              roll2.next = roll2.list[3]["idx"];
              console.log(roll2.prev);
            }
          }else{
           let tmp = roll2.prev;
            roll2.prev = roll2.curr;
            roll2.curr = roll2.next;
            roll2.next = tmp;
          }*/

        }, roll2.interval);
      }
    });
    const bbs = reactive({
      isMounted : false,
      isLoaded   : false,

      isShow:0,
      isTabMouseOver:0,

      page : 1,
      rows : 50,

      today   : "",
      tdate   : "",
      attrs   : [
        { key:'selected', highlight:true, dates:[ new Date() ] },
      ],

      weeks   : [],

      sdate   : "",             //
      edate   : "",             //
      state   : "",             // schedule type
      office  : "0",            // hq office
      team    : "0",            // hq team

      list : [], total : 0,

      dateFormat : (d) => {
        return [d.getFullYear(), d.getMonth()>8?d.getMonth()+1:'0'+(d.getMonth()+1), d.getDate()>9?d.getDate():'0'+d.getDate()].join('-');
      },

      addSchedule : () => {
        sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
          sdate  : bbs.sdate,
          edate  : bbs.edate,
          state  : bbs.state,
          office : bbs.office,
          team   : bbs.team
        }));

        router.push({
          name:'customerManagement-scheduleVisitAdd'
        });
      },

      showSchedule : (idx) => {
        sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
          sdate  : bbs.sdate,
          edate  : bbs.edate,
          state  : bbs.state,
          office : bbs.office,
          team   : bbs.team
        }));

        router.push({
          name:'customerManagement-scheduleVisitView-idx',
          params:{
            idx:idx
          }
        });
      },

      doSelect : () => {
        let nd = new Date(bbs.tdate);
        let td = nd.getDate() - nd.getDay();
        let fd = new Date(nd.setDate(td));
        let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+6);
        bbs.sdate = [fd.getFullYear(), fd.getMonth()>8?fd.getMonth()+1:'0'+(fd.getMonth()+1), fd.getDate()>9?fd.getDate():'0'+fd.getDate()].join('-');
        bbs.edate = [ld.getFullYear(), ld.getMonth()>8?ld.getMonth()+1:'0'+(ld.getMonth()+1), ld.getDate()>9?ld.getDate():'0'+ld.getDate()].join('-');

        for(let i=0; i<7; i++){
          bbs.attrs[0].dates[i] = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+i);
          bbs.weeks[i] = bbs.dateFormat(bbs.attrs[0].dates[i]);
        }
        bbs.doSearch();
      },

      doPrev : () => {
        let nd = new Date(bbs.sdate);
        bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-7);
        bbs.doSelect();
      },

      doNext : () => {
        let nd = new Date(bbs.sdate);
        bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()+7);
        bbs.doSelect();
      },

      doSearch : () => {
        if( bbs.isMounted == false ) return;
        let params = {
          sdate  : bbs.sdate,
          edate  : bbs.edate,
          state  : bbs.state,
          office : bbs.office,
          team   : bbs.team
        };

        axios.get("/rest/customermgr/getScheduleDates", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            bbs.list  = res.data.list;
            bbs.total = res.data.total;

            bbs.isLoaded = true;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }

          sessionStorage.removeItem('scheduleVisitWeekly');
        });
      },
    });

    const bbs_comment = reactive({
      isOwner  : false,
      isAuth   : false,
      isLoaded : false,

      idx : 0,
      info : {},

      showList: () => {
        router.push({ name:"CARROTZone-CarrotManualList" });
      },

      doDelete : () => {
        Swal.fire({
          title : "CARROT Manual",
          text: '삭제하시겠습니까?',
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            let params = {
              idx : bbs.idx
            };
            axios.post("/rest/carrotzone/carrotmanualDel", params).then((res) => {
              if( res.data.err == 0 ){
                router.push({
                  name: 'CARROTZone-CarrotManualList'
                })
              } else {
                if(res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          }
        });
      },

      showMod: () => {
        router.push({
          name   : 'CARROTZone-CarrotManualMod-idx',
          params : { idx:bbs.idx }
        });
      },

      getInfo: () => {
        let params = {
          idx :1
        };
        axios.get("/rest/carrotzone/carrotmanualView", { params: params }).then((res) => {
          if( res.data.err == 0 ){
            bbs.info = res.data;

            bbs.isOwner  = bbs.info.is_owner=='Y'?true:false;
            bbs.isAuth   = bbs.info.is_auth=='Y'?true:false;
            bbs.isLoaded = true;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      setGood: (idx) => {
        let params = {
          idx : idx
        };
        axios.get("/rest/carrotzone/mediazoneGood", { params: params }).then((res) => {
          if( res.data.err == 0 ){


            for(var i=0; i<main.mediazone.total; i++){
              if(main.mediazone.list[i].idx==idx) {
                main.mediazone.list[i].good = res.data.cnt;
                main.mediazone.list[i].is_good = res.data.is_good;
              }
            }

          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      }
    });
    onMounted(() => {
      // Mounted

      sales.doSearch();
      docs.doSearch();
      smp.doSearch();
      notice.doSearch();
      statistics.doSearch();
      mediazoneTopList.doSearch();
      main.isTab = 1;
      main.setTab(2);

      let prev=2;
      let curr=3;
      let next=1;


      setInterval(() => {
        //let tmp = 1;
        console.log(bbs.isTabMouseOver);
        if(bbs.isTabMouseOver==0){
          let tmp = prev;
          prev = curr;
          curr = next;
          next = tmp;
          main.setTab(curr);
        }
      }, 10000);

      main.initSearch();
      roll2.doSearch();
      let nd = new Date();
      bbs.today = bbs.dateFormat(nd);
      bbs.tdate = bbs.dateFormat(nd);
      bbs.doSelect();

      let ss = sessionStorage.getItem("scheduleVisitWeekly");
      if( ss ){
        ss = JSON.parse(ss);
        bbs.page  = ss.page?ss.page:1;
        if( ss.sdate ) bbs.sdate = ss.sdate;
        if( ss.edate ) bbs.edate = ss.edate;
        bbs.state  = ss.state?ss.state:"";
        bbs.office = ss.office>0?ss.office:0;
        bbs.team   = ss.team>0?ss.team:0;
      }

      bbs.isMounted = true;
      bbs.doSearch();

      bbs_comment.getInfo();

    });

    onUnmounted(() => {
      // UnMounted
      window.scrollTo(0,0);
    })

    return {pop, mnu, docs, smp, main, store, notice, bbs, roll2, mediazoneTopList, sales, statistics, bbs_comment};
  }
}
</script>
<style lang="scss" scoped>
.main-con .con-media-board .con-media-board-wrap {
  .slide-list {
    list-style: none; padding: 0; margin: 0;
    li{
      position: absolute; width:calc(100%);height: 100%; left: 100%; background-color:#fff;
      .slide-img {
        text-align:center;
      }
      &.currentroll{
        left: 0;
        transition: left .5s ease-in-out, right .5s ease-in-out;
      }
      &.prevroll{
        left: -100%;
        transition: left .5s ease-in-out, right .5s ease-in-out;
      }
      &.nextroll{
        left: 100%;
        display: block;
        // transition: none;
        transition: left .5s ease-in-out, right .5s ease-in-out;
      }
    }
    &.reverse li{
      &.prevroll{
        transition: none;
      }
      &.nextroll{
        transition: left .5s ease-in-out, right .5s ease-in-out;
      }
    }
  }
}
.row {
  margin:0 -10px;
  .col-6 {
    float:left; width:50%; padding:0 10px;
  }
}
.board-media {
  .title {
    .new {
      position: relative; width: 14px; height: 14px; font-size: 0; display: inline-block; vertical-align: middle; margin-left:5px;
      &::before {
        content: "N"; display: block; font-size: 10px; line-height: 12px; color: red;
      }
    }
  }
}
</style>